import {
  PageCommunityPropertiesQuery,
  PageCommunityPropertiesQueryVariables
} from '@src/graphql/gql-types';
import React, { useEffect, useState } from 'react';
import PropertyCard from '@src/components/Card/PropertyCard';
import styles from './PropertySearch.module.scss';
import Pagination from '@src/components/Pagination';
import PropertyFilter from '@src/components/PropertyFilter';
import { useQuery } from '@apollo/client';
import { pageCommunityPropertiesQuery } from '@src/graphql/queries';
import { Container } from '@src/elements/Grid';
import { chunkArray, uniq } from '@src/utils/arrays';
import LoadingState from '@src/components/LoadingState';
import { Filters, SortableField } from '../PropertyFilter/PropertyFilter';
import { useRouter } from 'next/router';
import { setApolloHeaders } from '@src/services/apollo';
import { pascalToDisplay } from '@src/utils/strings';

type PropertySearch = PageCommunityPropertiesQuery & {
  communityName?: string;
  communitySlug?: string;
  preview?: boolean;
};

const PAGE_SIZE = 8;

const PropertySearch: React.FC<PropertySearch> = ({ communitySlug }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMovedPage, setHasMovedPage] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortFieldName, setSortFieldName] = useState<SortableField>('maxPrice');
  const [filters, setFilters] = useState<Filters>({});

  const router = useRouter();
  const asPath = router.asPath;
  const preview = router.isPreview;

  if (preview) {
    setApolloHeaders(process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN);
  }

  // pull all community properties using a client side query
  const { data, loading, error } = useQuery<
    PageCommunityPropertiesQuery,
    PageCommunityPropertiesQueryVariables
  >(pageCommunityPropertiesQuery, {
    variables: {
      communitySlug,
      preview: preview,
      skip: 0,
      limit: 1000
    }
  });
  const propertyList =
    data?.product?.items.concat(data?.productCatalog?.items) || [];

  const propertyTotal =
    (data?.product?.total || 0) + (data?.productCatalog?.total || 0);

  // apply filters in 2 steps:
  //
  // 1. first apply all filters besides the property type filter. The result set of that filter will be used to determine all the available
  // property types that satisfy the other filters (e.g. price range, number of beds)
  // 2. after deriving all the available property types, apply one more filter of the currently selected property type filter
  // (The reason we are not doing it in one go, is that it would have resulted in the user not being able to filter by property type "B" after selecting
  // a property type "A" filter)
  let filteredPropertyList =
    Object.keys(filters)?.length > 0
      ? propertyList.filter((item) => {
          if (!item) {
            return false;
          }
          if (
            filters.maxPriceLow &&
            (item?.maxPrice || filters.maxPriceLow - 1) < filters.maxPriceLow
          ) {
            return false;
          }
          if (
            filters.maxPriceHigh &&
            (item?.maxPrice || filters.maxPriceHigh + 1) > filters.maxPriceHigh
          ) {
            return false;
          }
          if (
            filters.numBaths &&
            (item.noOfBaths || filters.numBaths + 1) < filters.numBaths
          ) {
            return false;
          }
          if (
            filters.numBeds &&
            (item.noOfBeds || filters.numBeds + 1) < filters.numBeds
          ) {
            return false;
          }
          if (
            filters.numCarSpaces &&
            (item.noOfCars || filters.numCarSpaces + 1) < filters.numCarSpaces
          ) {
            return false;
          }
          return true;
        })
      : propertyList;

  const availablePropertyTypes = uniq(
    filteredPropertyList?.map((item) => item?.propertyType as string)
  );

  filteredPropertyList = filteredPropertyList.filter((item) => {
    if (
      filters.propertyTypes &&
      !filters.propertyTypes.includes(item?.propertyType as string)
    ) {
      return false;
    }
    return true;
  });

  // sort by price
  const sortedPropertyList = filteredPropertyList.sort((a, b) => {
    const aFieldValue = a?.[sortFieldName] || 0;
    const bFieldValue = b?.[sortFieldName] || 0;
    if (aFieldValue > bFieldValue) {
      return sortOrder === 'asc' ? 1 : -1;
    } else if (aFieldValue < bFieldValue) {
      return sortOrder === 'asc' ? -1 : 1;
    } else {
      return 0;
    }
  });

  // split to pages and render the contents of page number <current page>
  const currentPagePropertyList = chunkArray(sortedPropertyList, PAGE_SIZE)[
    currentPage
  ];

  useEffect(() => {
    if (!loading && propertyTotal) {
      setNumPages(Math.ceil((propertyTotal || 0) / PAGE_SIZE));
    }
  }, [propertyTotal, loading]);

  if (error) {
    // TODO - handle visually
    console.log(error);
  }

  return (
    <div className={styles.propertySearchContainer}>
      {loading && (
        <div className={styles.loadingState}>
          <LoadingState />
        </div>
      )}
      <PropertyFilter
        onFiltersUpdate={(newValues) => {
          setFilters(newValues);
        }}
        onSortUpdate={({ fieldName, order = 'asc' }) => {
          setSortOrder(order);
          setSortFieldName(fieldName);
        }}
        availablePropertyTypes={availablePropertyTypes || []}
      />
      <Container>
        <p className={styles.searchResultsMessage}>
          {currentPagePropertyList?.length || 0} home type
          {(currentPagePropertyList?.length > 1 ||
            !currentPagePropertyList?.length) &&
            's'}{' '}
          found
        </p>

        <div className={styles.propertyGrid}>
          {currentPagePropertyList?.length > 0 &&
            currentPagePropertyList?.map((property) => (
              <PropertyCard
                key={property?.slug}
                slug={property?.slug as string}
                title={property?.title as string}
                propertyType={pascalToDisplay(property?.propertyType as string)}
                image={property?.gallery as any}
                statusBadgeColour={
                  property?.statusBadge?.colour as 'yellow' | 'red'
                }
                statusBadgeLabel={property?.statusBadge?.bannerLabel as string}
                {...(property?.minPrice && {
                  minPrice: property.minPrice
                })}
                {...(property?.maxPrice && {
                  maxPrice: property.maxPrice
                })}
                noOfBaths={property?.noOfBaths as number}
                noOfBeds={property?.noOfBeds as number}
                noOfCars={property?.noOfCars as number}
                urlPath={`${asPath}${property?.slug as string}`}
              />
            ))}
        </div>

        {numPages > 1 && (
          <div className={styles.paginationWrapper}>
            <Pagination
              numPages={numPages}
              onPageChanged={(newPage) => {
                if (!hasMovedPage) {
                  setHasMovedPage(true);
                }
                setCurrentPage(newPage);
              }}
            />
          </div>
        )}
        {!currentPagePropertyList?.length && (
          <p className={styles.noResultsMessage}>
            No results have been found for your search criteria. Please try
            broadening your search
          </p>
        )}
      </Container>
    </div>
  );
};

export default PropertySearch;
